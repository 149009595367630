import React, { useState, useEffect } from "react";
import Home from "../Home";
import { getCapitalized } from "../../utils/Helper";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createEducation,  
  getugEducationDetail,
} from "../../redux/actions/Api";
import { HELP_TEXT, PATHS } from "../../utils";
import { toast } from "react-toastify";
import Success from "../notification/Success";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Loader from "../Loader";
import {
  GRADE_REGEX,
  NUM_REGEX,
} from "../../utils/Regex";
import { resetForms, unsavedchanges } from "../../redux/actions";
import Error from "../notification/Error";
import { Notes } from "../common/Notes";
import { clear } from "@testing-library/user-event/dist/clear";
import {
  AppCommon,
  ApplicationViewPage,
  UGEducationDetails,
} from "../constantLabelFile";

let DraftBtn = "";

const EducationDetailUG = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    picklistdata,
    createeducationres,
    error,
    ugedudata,
    dashboardresponse,
  } = useSelector((state) => state.collections);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectednewCountry, setSelectednewCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [district, setDistrict] = useState([]);
  const [selectedState1, setSelectedState1] = useState("");
  const [selectedDistrict1, setSelectedDistrict1] = useState("");
  const [district1, setDistrict1] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pickListVal, setPickListVal] = useState("");
  const [tenthMedium, setTenthMedium] = useState("");
  const [twelvthMedium, setTwelvthMedium] = useState("");
  const [percentGrade, setPercentGrade] = useState("");
  const [percentGradeTwelveth, setPercentGradeTwelveth] = useState("");
  const [readOnly, setReadOnly] = useState(false);

  const [showMsg, setShowMsg] = useState("")
  const [capLetter, setCapLetter] = useState("")
  const [capLetterGrade, setCapLetterGrade] = useState("")

  useEffect(() => {
    if (ugedudata == undefined) {
      setLoader(true);
    } else {
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }, [ugedudata]);

  useEffect(() => {
    let formData = {
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
    };
    if (formData) {
      dispatch(getugEducationDetail(formData));
    }
  }, []);

  const educationData =
    ugedudata && ugedudata?.data?.records[0]?.hed__Education_History__r;

  useEffect(() => {
    if (error) {
      setLoader(false);
      toast.error(<Error error={error} />, {
        position: "top-center",
        autoClose: 5000,
        className: "Toastify__toast-theme--colored.Toastify__toast--warning",
      });
      setTimeout(() => {
        dispatch(resetForms());
      }, 5000);
    }
  }, [error]);

  useEffect(() => {
    setPickListVal(picklistdata?.data);
  }, [picklistdata]);

  useEffect(() => {
    if (
      ugedudata?.data?.records[0]?.hed__Education_History__r &&
      picklistdata &&
      pickListVal
    ) {
      setValue(
        "schoolNameTenth",
        educationData?.records[0]?.hed__Educational_Institution_Name__c
      );
      setValue(
        "schoolNameTwelveth",
        educationData?.records[1]?.hed__Educational_Institution_Name__c
      );
      setValue(
        "schoolAddressTenth",
        educationData?.records[0]?.Address_of_the_School__c
      );
      setValue(
        "schoolAddressTwelveth",
        educationData?.records[1]?.Address_of_the_School__c
      );
      setValue("countryTenth", educationData?.records[0]?.Country__c);
      setSelectedCountry(educationData?.records[0]?.Country__c);
      setValue("countryTwelveth", educationData?.records[1]?.Country__c);
      setSelectednewCountry(educationData?.records[1]?.Country__c);
      setValue(
        "otherCountrySpecifyTenth",
        educationData.records[0]?.Please_Specify_Country__c
      );
      setValue(
        "otherCountrySpecifyTwelveth",
        educationData.records[1]?.Please_Specify_Country__c
      );
      setValue("stateTenth", educationData?.records[0]?.State__c);
      setSelectedState(educationData?.records[0]?.State__c);

      if (pickListVal && educationData?.records[0]?.Country__c === "India") {
        const stateObject =
          educationData?.records[0]?.Country__c &&
          pickListVal?.districts?.India[educationData?.records[0]?.State__c];
        const dis = Object?.values(stateObject);
        setDistrict(dis);
      }
      if (pickListVal && educationData?.records[1]?.Country__c === "India") {
        const stateObject1 =
          educationData?.records[1]?.Country__c &&
          pickListVal?.districts?.India[educationData?.records[1]?.State__c];
        const dis1 = Object?.values(stateObject1);
        setDistrict1(dis1);
      }

      setValue("stateTwelveth", educationData?.records[1]?.State__c);
      setSelectedState1(educationData?.records[1]?.State__c);
      setValue("districtTenth", educationData?.records[0]?.District__c);
      setSelectedDistrict(educationData?.records[0]?.District__c);
      setValue("districtTwelveth", educationData?.records[1]?.District__c);
      setSelectedDistrict1(educationData?.records[1]?.District__c);
      setValue(
        "boardTenth",
        educationData?.records[0]?.School_College_Board__c
      );
      setValue(
        "boardTwelth",
        educationData?.records[1]?.School_College_Board__c
      );
      setValue("subjectsTenth", educationData?.records[0]?.Major_Subjects__c);
      setValue(
        "subjectsTwelveth",
        educationData?.records[1]?.Major_Subjects__c
      );

      setValue("stateTwelveth", educationData?.records[1]?.State__c)
      setSelectedState1(educationData?.records[1]?.State__c)
      setValue("districtTenth", educationData?.records[0]?.District__c)
      setSelectedDistrict(educationData?.records[0]?.District__c)
      setValue("districtTwelveth", educationData?.records[1]?.District__c)
      setSelectedDistrict1(educationData?.records[1]?.District__c)
      setValue("boardTenth", educationData?.records[0]?.School_College_Board__c)
      setValue("boardTwelth", educationData?.records[1]?.School_College_Board__c)
      setValue("subjectsTenth", educationData?.records[0]?.Major_Subjects__c)
      setValue("subjectsTwelveth", educationData?.records[1]?.Major_Subjects__c)
      
      setValue("gradeSystemTenth", educationData?.records[0]?.Percentage_Grade__c)
      setPercentGrade(educationData?.records[0]?.Percentage_Grade__c)
      setValue("gradeSystemTwelveth", educationData?.records[1]?.Percentage_Grade__c)
      setPercentGradeTwelveth(educationData?.records[1]?.Percentage_Grade__c)
      
      setValue("percentageTenth", educationData?.records[0]?.Overall_Percentage_Grade__c)
      setCapLetter(educationData?.records[0]?.Overall_Percentage_Grade__c)
      setValue("percentageTwelveth", educationData?.records[1]?.Overall_Percentage_Grade__c)
      setCapLetterGrade(educationData?.records[1]?.Overall_Percentage_Grade__c)

      setValue("passingYearTenth", educationData?.records[0]?.Year_Of_Passing__c)
      setValue("passingYearTwelveth", educationData?.records[1]?.Year_Of_Passing__c)
      setValue("schoolTypeTenth", educationData?.records[0]?.Type_of_School_College_Board__c)
      setValue("schoolTypeTwelveth", educationData?.records[1]?.Type_of_School_College_Board__c)
      setValue("mediumInstTenth", educationData?.records[0]?.Medium_of_instruction__c)
      setTenthMedium(educationData?.records[0]?.Medium_of_instruction__c)
      setValue("mediumInstTwelveth", educationData?.records[1]?.Medium_of_instruction__c)
      setTwelvthMedium(educationData?.records[1]?.Medium_of_instruction__c)
      setValue("specifyMediumTenth", educationData?.records[0]?.Please_Specify_Medium_of_Instruction__c)
      setValue("specifyMediumTwelveth", educationData?.records[1]?.Please_Specify_Medium_of_Instruction__c)
      setValue("residenceTenth", educationData?.records[0]?.Region_of_Residence__c)
      setValue("residenceTwelveth", educationData?.records[1]?.Region_of_Residence__c)
      setValue("schoolGroupTenth", educationData?.records[0]?.Class_12_school_group__c)
      setValue("schoolGroupTwelveth", educationData?.records[1]?.Class_12_school_group__c)
    }
  }, [educationData, ugedudata, picklistdata, pickListVal]);

  let draftMsg = {
    message: "Details Saved Successfully.",
  };

  useEffect(() => {
    if (createeducationres) {
      dispatch(unsavedchanges());
      toast.success(
        <Success response={!DraftBtn ? createeducationres : draftMsg} />,
        {
          position: "top-center",
          autoClose: 2000,
        }
      );
      setLoader(false);
      if (!DraftBtn) {
        setTimeout(() => {
          navigate(
            PATHS.SOCIALECOUG_STR +
              dashboardresponse?.data?.allDataOnDashboard[0]?.appId
          );
        }, 500);
      } else if (DraftBtn) {
        setTimeout(() => {
          draftMsg.message = "";
        }, 500);
      }
    }
  }, [createeducationres]);

  const _onSubmit = (values) => {
    let formData = {
      userId: localStorage.getItem("userId"),
      contactId: localStorage.getItem("contactId"),
      applicationId: localStorage.getItem("applicationId"),
      schoolNameTenth: values.schoolNameTenth,
      schoolNameTwelveth: values.schoolNameTwelveth,
      schoolAddressTenth: values.schoolAddressTenth,
      schoolAddressTwelveth: values.schoolAddressTwelveth,
      countryTenth: values.countryTenth,
      countryTwelveth: values.countryTwelveth,
      otherCountrySpecifyTenth:
        values.countryTenth === "Others" ? values.otherCountrySpecifyTenth : "",
      otherCountrySpecifyTwelveth:
        values.countryTwelveth === "Others"
          ? values.otherCountrySpecifyTwelveth
          : "",
      stateTenth:
        values.countryTenth === "India"
          ? values.stateTenth
            ? values.stateTenth
            : ""
          : "",
      stateTwelveth:
        values.countryTwelveth === "India"
          ? values.stateTwelveth
            ? values.stateTwelveth
            : ""
          : "",
      districtTenth:
        values.countryTenth === "India"
          ? values.districtTenth
            ? values.districtTenth
            : null
          : null,
      districtTwelveth:
        values.countryTwelveth === "India"
          ? values.districtTwelveth
            ? values.districtTwelveth
            : null
          : null,
      boardTenth: values.boardTenth,
      boardTwelth: values.boardTwelth,
      subjectsTenth: values.subjectsTenth,
      subjectsTwelveth: values.subjectsTwelveth,
      gradeSystemTenth: values.gradeSystemTenth,
      gradeSystemTwelveth: values.gradeSystemTwelveth,
      percentageTenth: values.percentageTenth,
      percentageTwelveth: values.percentageTwelveth,
      passingYearTenth: parseInt(values.passingYearTenth),
      passingYearTwelveth: parseInt(values.passingYearTwelveth),
      schoolTypeTenth: values.schoolTypeTenth,
      schoolTypeTwelveth: values.schoolTypeTwelveth,
      mediumInstTenth: values.mediumInstTenth,
      mediumInstTwelveth: values.mediumInstTwelveth,
      specifyMediumTenth:
        tenthMedium === "Other" ? values.specifyMediumTenth : "",
      specifyMediumTwelveth:
        twelvthMedium === "Other" ? values.specifyMediumTwelveth : "",
      residenceTenth: values.residenceTenth,
      residenceTwelveth: values.residenceTwelveth,
      schoolGroupTenth: values.schoolGroupTenth,
      schoolGroupTwelveth: values.schoolGroupTwelveth,
    };

    if (formData) {
      setLoader(true);
      dispatch(createEducation(formData));
    }
  };

  const handleCountry = (e) => {
    setSelectedCountry(e.target.value);
    dispatch(unsavedchanges(e.target.value));
    clearErrors("countryTenth");
  };
  const handlenewCountry = (e) => {
    setSelectednewCountry(e.target.value);
    dispatch(unsavedchanges(e.target.value));
    clearErrors("countryTwelveth");
  };

  const handleState = (e) => {
    setSelectedState(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict(dis);
    setSelectedDistrict("");

    dispatch(unsavedchanges(e.target.value));
    clearErrors("stateTenth");
  };

  const handlenewState = (e) => {
    setSelectedState1(e.target.value);
    const stateObject = pickListVal?.districts?.India[e.target.value];
    const dis = Object?.values(stateObject);
    setDistrict1(dis);
    setSelectedDistrict1("");

    dispatch(unsavedchanges(e.target.value));
    clearErrors("stateTwelveth");
  };

  const handlePercent = (e) => {
    setPercentGrade(e.target.value);
    setValue("percentageTenth", "");
    clearErrors(["percentageTenth"]);
    clearErrors("gradeSystemTenth");
    dispatch(unsavedchanges(e.target.value));
  };

  const handlePercentTwelveth = (e) => {
    setPercentGradeTwelveth(e.target.value);
    setValue("percentageTwelveth", "");
    clearErrors(["percentageTwelveth"]);
    clearErrors("gradeSystemTwelveth");
    dispatch(unsavedchanges(e.target.value));
  };

  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year1}-${month}-${day}`;

  useEffect(() => {
    if (
      ugedudata?.data?.records[0]?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Started" ||
      ugedudata?.data?.records[0]?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Draft" ||
      ugedudata?.data?.records[0]?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Failed" ||
      ugedudata?.data?.records[0]?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Pending" ||
      ugedudata?.data?.records[0]?.hed__Applications_Applicant__r?.records[0]
        ?.hed__Application_Status__c === "Payment Submitted"
    ) {
      if (
        formattedDate >
        ugedudata?.data?.records[0]?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
      setShowMsg(<Notes name="ADM_Cycle_Validation_App" />);
      } else {
        setReadOnly(false);
        setShowMsg("");
      }
    } else {
      if (
        formattedDate <
        ugedudata?.data?.records[0]?.hed__Applications_Applicant__r?.records[0]
          ?.Admission_Cycle__r?.Application_Submission_Date__c
      ) {
        setReadOnly(true);
        setShowMsg("");
      } else {
        setReadOnly(true);
        setShowMsg(<Notes name="ADM_Cycle_Validation_App" />)
      }
    }
  }, [ugedudata]);

  const handlecapClick = (e) => {   
    setCapLetter(getCapitalized(e.target?.value))
    clearErrors("percentageTenth");
  };

  const handlecapClickGrade = (e) => {
    setCapLetterGrade(getCapitalized(e.target?.value))
    dispatch(unsavedchanges(e.target.value)); 
    clearErrors("percentageTwelveth")
  }

  return (
    <Home>
      <div class="right_panel">
        <div class="w100">
          <p className="top_head_styl">{showMsg}</p>
          <h4 className="top_head">
            {getCapitalized(UGEducationDetails.Undergraduate_Programme)}
          </h4>
          <span className="help_link">
            <p>
              <NavLink to={PATHS.RAISEQUERY}>{HELP_TEXT}</NavLink>{" "}
              <Tooltip id="helpTooltip">               
                <p>{ApplicationViewPage.Help_Tooltip_Text}</p>
              </Tooltip>
              <i
                data-tooltip-id="helpTooltip"
                class="fa-solid fa-circle-info"
              ></i>
            </p>
          </span>
        </div>
        <div className="w100 scroll_styl">
          <div class="heading_styl head_font">          
            <h4>{getCapitalized(UGEducationDetails.Education_Details)}</h4>
          </div>
          <Loader show={loader} />

          <form
            onSubmit={handleSubmit(_onSubmit)}           
            disabled={readOnly}
          >
            <div class="form_sec form_part ug_eduform">
              <div class="form_styl">
                <table>
                  <tr>
                    <th></th>                   
                    <th>{UGEducationDetails.Class_10_Details}</th>                    
                    <th>                     
                      {UGEducationDetails.Class_12_Details}                     
                      <Tooltip id="programTool">                      
                        <Notes name="X12thStandard" />
                      </Tooltip>
                      <i
                        data-tooltip-id="programTool"
                        class="fa-solid fa-circle-info"
                      ></i>
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Name}{" "}
                      <span class="redstyl">* </span>{" "}
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("schoolNameTenth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolNameTenth");
                        }}
                        className={`${
                          errors.schoolNameTenth ? "error_msg" : ""
                        }`}
                        autocomplete="off"
                        disabled={readOnly}
                      />
                      {errors.schoolNameTenth && (
                        <div className="error_shown">
                          {errors.schoolNameTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("schoolNameTwelveth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolNameTwelveth");
                        }}
                        className={`${
                          errors.schoolNameTwelveth ? "error_msg" : ""
                        }`}
                        autocomplete="off"
                        disabled={readOnly}
                      />
                      {errors.schoolNameTenth && (
                        <div className="error_shown">
                          {errors.schoolNameTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>                    
                      {UGEducationDetails.School_Address}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("schoolAddressTenth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolAddressTenth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.schoolAddressTenth ? "error_msg" : ""
                        }`}
                        autocomplete="off"
                      />
                      {errors.schoolAddressTenth && (
                        <div className="error_shown">
                          {errors.schoolAddressTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("schoolAddressTwelveth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolAddressTwelveth");
                        }}
                        className={`${
                          errors.schoolAddressTwelveth ? "error_msg" : ""
                        }`}
                        disabled={readOnly}
                        autocomplete="off"
                      />
                      {errors.schoolAddressTwelveth && (
                        <div className="error_shown">
                          {errors.schoolAddressTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Country}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <select
                        {...register("countryTenth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        value={selectedCountry}
                        onChange={handleCountry}
                        className={`${errors.countryTenth ? "error_msg" : ""}`}
                        disabled={readOnly}
                      >
                        <option value="">
                          {AppCommon.Dropdown_Default_Option}
                        </option>                       
                        <option value="India">
                          {AppCommon.Dropdown_India_Option}
                        </option>                       
                        <option value="Others">
                          {AppCommon.Dropdown_Others_Option}
                        </option>
                      </select>
                      {selectedCountry === "Others" && (
                        <>
                          <label>                           
                            {UGEducationDetails.Please_Specify} <span>*</span>
                          </label>
                          <input
                            {...register("otherCountrySpecifyTenth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                            })}
                            autoComplete="off"
                            className={`${
                              errors.otherCountrySpecifyTenth ? "error_msg" : ""
                            }`}
                          />
                          {errors.otherCountrySpecifyTenth && (
                            <div className="error_shown">
                              {errors.otherCountrySpecifyTenth?.message}
                            </div>
                          )}
                        </>
                      )}
                      {errors.countryTenth && (
                        <div className="error_shown">
                          {errors.countryTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("countryTwelveth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.countryTwelveth ? "error_msg" : ""
                        }`}
                        value={selectednewCountry}
                        onChange={handlenewCountry}
                        disabled={readOnly}
                      >
                        <option value="">
                          {AppCommon.Dropdown_Default_Option}
                        </option>                        
                        <option value="India">
                          {AppCommon.Dropdown_India_Option}
                        </option>                      
                        <option value="Others">
                          {AppCommon.Dropdown_Others_Option}
                        </option>
                      </select>
                      {selectednewCountry === "Others" && (
                        <>
                          <label>                          
                            {UGEducationDetails.Please_Specify}{" "}
                            <span class="redstyl">* </span>
                          </label>
                          <input
                            {...register("otherCountrySpecifyTwelveth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                            })}
                            autoComplete="off"
                            className={`${
                              errors.otherCountrySpecifyTwelveth
                                ? "error_msg"
                                : ""
                            }`}
                            disabled={readOnly}
                          />
                          {errors.otherCountrySpecifyTwelveth && (
                            <div className="error_shown">
                              {errors.otherCountrySpecifyTwelveth?.message}
                            </div>
                          )}
                        </>
                      )}
                      {errors.countryTwelveth && (
                        <div className="error_shown">
                          {errors.countryTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>                     
                      {UGEducationDetails.State_Union_Residence}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="stateTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="stateTooltip">
                        <p>
                          {
                            UGEducationDetails.State_Union_Residence_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                    </td>
                    <td>
                      <select
                        {...register("stateTenth", {
                          required: {
                            value: selectedCountry === "Others" ? false : true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          selectedCountry !== "Others" && errors.stateTenth
                            ? "error_msg"
                            : ""
                        }`}
                        value={selectedState}
                        onChange={handleState}
                        disabled={
                          !selectedCountry ||
                          selectedCountry === "Others" ||
                          readOnly
                        }
                      >
                        {selectedCountry === "Others" ? (
                          <option value="">
                            {AppCommon.Dropdown_NA_Option}
                          </option>
                        ) : (
                          <option value="" disabled selected>                          
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                        )}
                        {selectedCountry !== "Others" &&
                          pickListVal?.states?.map((stateobj, ind) => (
                            <option value={stateobj.Id} key={ind}>
                              {stateobj.value}
                            </option>
                          ))}
                      </select>
                      {selectedCountry !== "Others" && errors.stateTenth && (
                        <div className="error_shown">
                          {errors.stateTenth?.message}
                        </div>
                      )}
                    </td>

                    <td>
                      <select
                        {...register("stateTwelveth", {
                          required: {
                            value:
                              selectednewCountry === "Others" ? false : true,
                            message: "Please select value",
                          },
                        })}
                        value={selectedState1}
                        onChange={handlenewState}
                        disabled={
                          !selectednewCountry ||
                          selectednewCountry === "Others" ||
                          readOnly
                        }
                        className={`${
                          selectednewCountry !== "Others" &&
                          errors.stateTwelveth
                            ? "error_msg"
                            : ""
                        }`}
                      >
                        {selectednewCountry === "Others" ? (
                          <option value="">
                            {AppCommon.Dropdown_NA_Option}
                          </option>
                        ) : (
                          <option value="" disabled selected>
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                        )}
                        {selectednewCountry !== "Others" &&
                          pickListVal?.states?.map((stateobj, ind) => (
                            <option value={stateobj.Id} key={ind}>
                              {stateobj.value}
                            </option>
                          ))}
                      </select>
                      {selectednewCountry !== "Others" &&
                        errors.stateTwelveth && (
                          <div className="error_shown">
                            {errors.stateTwelveth?.message}
                          </div>
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.District}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <select
                        {...register("districtTenth", {
                          required: {
                            value: selectedCountry === "Others" ? false : true,
                            message: "Please select value",
                          },
                        })}
                        value={selectedDistrict}
                        onChange={(e) => {
                          setSelectedDistrict(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("districtTenth");
                        }}
                        disabled={
                          !selectedState ||
                          selectedCountry === "Others" ||
                          readOnly
                        }
                        className={`${
                          selectedCountry !== "Others" && errors.districtTenth
                            ? "error_msg"
                            : ""
                        }`}
                      >
                        {selectedCountry === "Others" ? (                        
                          <option value="">
                            {AppCommon.Dropdown_NA_Option}
                          </option>
                        ) : (
                          <option disabled selected value="">                           
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                        )}
                        {selectedCountry !== "Others" &&
                          district &&
                          district?.map((distObj, ind) => (
                            <option key={ind} value={distObj.Id}>
                              {distObj.Name}
                            </option>
                          ))}
                      </select>
                      {selectedCountry !== "Others" && errors.districtTenth && (
                        <div className="error_shown">
                          {errors.districtTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("districtTwelveth", {
                          required: {
                            value:
                              selectednewCountry === "Others" ? false : true,
                            message: "Please select value",
                          },
                        })}
                        value={selectedDistrict1}
                        onChange={(e) => {
                          setSelectedDistrict1(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("districtTwelveth");
                        }}
                        className={`${
                          selectednewCountry !== "Others" &&
                          errors.districtTwelveth
                            ? "error_msg"
                            : ""
                        }`}
                        disabled={
                          !selectedState1 ||
                          selectednewCountry === "Others" ||
                          readOnly
                        }
                      >
                        {selectednewCountry === "Others" ? (                          
                          <option value="">
                            {AppCommon.Dropdown_NA_Option}
                          </option>
                        ) : (
                          <option disabled selected value="">                          
                            {AppCommon.Dropdown_Default_Option}
                          </option>
                        )}
                        {selectednewCountry !== "Others" &&
                          district1 &&
                          district1?.map((distObj, ind) => (
                            <option key={ind} value={distObj.Id}>
                              {distObj.Name}
                            </option>
                          ))}
                      </select>
                      {selectednewCountry !== "Others" &&
                        errors.districtTwelveth && (
                          <div className="error_shown">
                            {errors.districtTwelveth?.message}
                          </div>
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Board}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <select
                        {...register("boardTenth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("boardTenth");
                        }}
                        className={`${errors.boardTenth ? "error_msg" : ""}`}
                        disabled={readOnly}
                      >
                        <option value="" disabled selected>                        
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.schlClgBoard?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.boardTenth && (
                        <div className="error_shown">
                          {errors.boardTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("boardTwelth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("boardTwelth");
                        }}
                        className={`${errors.boardTwelth ? "error_msg" : ""}`}
                        disabled={readOnly}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.schlClgBoard?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.boardTwelth && (
                        <div className="error_shown">
                          {errors.boardTwelth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Major_Subjects}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("subjectsTenth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        autocomplete="off"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("subjectsTenth");
                        }}
                        className={`${errors.subjectsTenth ? "error_msg" : ""}`}
                        disabled={readOnly}
                      />
                      {errors.subjectsTenth && (
                        <div className="error_shown">
                          {errors.subjectsTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("subjectsTwelveth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                        })}
                        autocomplete="off"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("subjectsTwelveth");
                        }}
                        className={`${
                          errors.subjectsTwelveth ? "error_msg" : ""
                        }`}
                        disabled={readOnly}
                      />
                      {errors.subjectsTwelveth && (
                        <div className="error_shown">
                          {errors.subjectsTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Grade} <span class="redstyl">* </span>
                    </td>
                    <td>
                      <select
                        {...register("gradeSystemTenth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={handlePercent}
                        className={`${
                          errors.gradeSystemTenth ? "error_msg" : ""
                        }`}
                        disabled={readOnly}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.percentageGrade?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.gradeSystemTenth && (
                        <div className="error_shown">
                          {errors.gradeSystemTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("gradeSystemTwelveth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        className={`${
                          errors.gradeSystemTwelveth ? "error_msg" : ""
                        }`}
                        onChange={handlePercentTwelveth}
                        disabled={readOnly}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.percentageGrade?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.gradeSystemTwelveth && (
                        <div className="error_shown">
                          {errors.gradeSystemTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>

                  <tr>
                    {percentGrade ? (
                      <td>
                        {UGEducationDetails.Grade_Overall}{" "}
                        <span class="redstyl">* </span>
                      </td>
                    ) : percentGradeTwelveth ? (
                      <td>
                        {UGEducationDetails.Grade_Overall}{" "}
                        <span class="redstyl">* </span>
                      </td>
                    ) : percentGrade && percentGradeTwelveth ? (
                      <td>
                        {UGEducationDetails.Grade_Overall}{" "}
                        <span class="redstyl">* </span>
                      </td>
                    ) : (
                      ""
                    )}

                    {percentGrade && (                       
                      <td>                       
                        {percentGrade === "Grade" ? ( 
                          // <>                                         
                          <input
                            {...register("percentageTenth", {
                              required: {
                                value: true,
                                message: "Please enter Grade",
                              },
                              pattern: {
                                value: GRADE_REGEX,
                                message: "Please enter valid grade value",
                              },
                            })}    
                            maxLength={"10"} 
                            autoComplete="off"   
                            value={capLetter}
                            onChange={(e)=>handlecapClick(e)}                    
                            disabled={readOnly}
                            className={`${
                              errors.percentageTenth ? "error_msg" : ""
                            }`}
                          />
                        //    {/* {errors.percentageTenth && percentGrade === "Grade" && (
                        //   <div className="error_shown">
                        //     {errors.percentageTenth?.message}
                        //   </div>
                        // )}                         
                        //   </> */}
                        ) : percentGrade === "Percentage" ? (
                          // <>
                          <input
                            {...register("percentageTenth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                              pattern: {
                                value: NUM_REGEX,
                                message: "Please enter valid value",
                              },
                              validate: (val) => {
                                if (val > 100) {
                                  return "Percentage value not exceed 100";
                                }
                              },
                            })}
                            autoComplete="off"
                            maxLength={5}
                            onChange={(e) => {
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("percentageTenth");
                            }}
                            disabled={readOnly}
                            className={`${
                              errors.percentageTenth ? "error_msg" : ""
                            }`}
                          />
                        //    {/* {errors.percentageTenth && percentGrade === "Percentage" && (
                        //   <div className="error_shown">
                        //     {errors.percentageTenth?.message}
                        //   </div>
                        // )}                           
                        //    </> */}
                        ) : percentGrade === "CGPA" ?  (
                          // <>
                          <input
                            {...register("percentageTenth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                              pattern: {
                                value: NUM_REGEX,
                                message: "Please enter valid value",
                              },
                              validate: (val) => {
                                if (val > 100) {
                                  return "Your entry does not match the allowed patten.";
                                }
                              },
                            })}
                            maxLength={5}
                            autoComplete="off"
                            onChange={(e) => {
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("percentageTenth");
                            }}
                            disabled={readOnly}
                            className={`${
                              errors.percentageTenth ? "error_msg" : ""
                            }`}
                          />
                        //    {errors.percentageTenth && percentGrade === "CGPA" && (
                        //   <div className="error_shown">
                        //     {errors.percentageTenth?.message}
                        //   </div>
                        // )}                         
                        //    </>
                        ) : ""}
                        {errors.percentageTenth && (
                          <div className="error_shown">
                            {errors.percentageTenth?.message}
                          </div>
                        )}
                      </td>
                    )}

                    {percentGradeTwelveth && !percentGrade && <td></td>}

                    {percentGradeTwelveth && 
                    <td>
                      {percentGradeTwelveth === "Grade" ? (
                        <input
                          {...register("percentageTwelveth", {
                            required: {
                              value: true,
                              message: "Please enter Grade",
                            },
                            pattern: {
                              value: GRADE_REGEX,
                              message: "Please enter valid grade value",
                            },
                          })}
                          maxLength={"10"}
                          autoComplete="off"
                          value={capLetterGrade}
                          onChange={(e) => {handlecapClickGrade(e)}}
                          disabled={readOnly}
                          className={`${errors.percentageTwelveth ? "error_msg" : ""
                            }`}
                          />
                        ) : percentGradeTwelveth === "Percentage" ? (
                          <input
                            {...register("percentageTwelveth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                              pattern: {
                                value: NUM_REGEX,
                                message: "Please enter valid value",
                              },
                              validate: (val) => {
                                if (val > 100) {
                                  return "Percentage value not exceed 100";
                                }
                              },
                            })}
                            autoComplete="off"
                            maxLength={5}
                            onChange={(e) => {
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("percentageTwelveth");
                            }}
                            disabled={readOnly}
                            className={`${
                              errors.percentageTwelveth ? "error_msg" : ""
                            }`}
                          />
                        ) : percentGradeTwelveth === "CGPA" ? (
                          <input
                            {...register("percentageTwelveth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                              pattern: {
                                value: NUM_REGEX,
                                message: "Please enter valid value",
                              },
                              validate: (val) => {
                                if (val > 100) {
                                  return "Your entry does not match the allowed patten.";
                                }
                              },
                            })}
                            autoComplete="off"
                            maxLength={4}
                            onChange={(e) => {
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("percentageTwelveth");
                            }}
                            disabled={readOnly}
                            className={`${
                              errors.percentageTwelveth ? "error_msg" : ""
                            }`}
                          />
                        ) : ""}
                        {errors.percentageTwelveth && (
                          <div className="error_shown">
                            {errors.percentageTwelveth?.message}
                          </div>
                        )}
                      </td>
                    }
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Passing_Year}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="yearTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="yearTooltip">
                        <p>
                          {UGEducationDetails.Passing_Year_Tooltip_Message}
                        </p>
                      </Tooltip>
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("passingYearTenth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                          pattern: {
                            value: NUM_REGEX,
                            message:
                              "Your entry does not match the allowed pattern.",
                          },
                          maxLength: {
                            value: 4,
                            message: "Please enter valid year",
                          },
                          minLength: {
                            value: 4,
                            message: "Please enter valid year",
                          },
                        })}
                        autocomplete="off"
                        maxLength="4"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("passingYearTenth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.passingYearTenth ? "error_msg" : ""
                        }`}
                      />
                      {errors.passingYearTenth && (
                        <div className="error_shown">
                          {errors.passingYearTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        {...register("passingYearTwelveth", {
                          required: {
                            value: true,
                            message: "Please enter value",
                          },
                          pattern: {
                            value: NUM_REGEX,
                            message:
                              "Your entry does not match the allowed pattern.",
                          },
                          maxLength: {
                            value: 4,
                            message: "Please enter valid year",
                          },
                          minLength: {
                            value: 4,
                            message: "Please enter valid year",
                          },
                        })}
                        maxLength="4"
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("passingYearTwelveth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.passingYearTwelveth ? "error_msg" : ""
                        }`}
                        autocomplete="off"
                      />
                      {errors.passingYearTwelveth && (
                        <div className="error_shown">
                          {errors.passingYearTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Type}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <select
                        {...register("schoolTypeTenth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolTypeTenth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.schoolTypeTenth ? "error_msg" : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.typeOfSchlBoard?.map((edu, ind) => (
                            <option key={ind} value={edu.value}>
                              {edu.value}
                            </option>
                          ))}
                      </select>
                      {errors.schoolTypeTenth && (
                        <div className="error_shown">
                          {errors.schoolTypeTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("schoolTypeTwelveth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolTypeTwelveth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.schoolTypeTwelveth ? "error_msg" : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.typeOfSchlBoard?.map((edu, ind) => (
                            <option key={ind} value={edu.value}>
                              {edu.value}
                            </option>
                          ))}
                      </select>
                      {errors.schoolTypeTwelveth && (
                        <div className="error_shown">
                          {errors.schoolTypeTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Medium_Of_Instruction}{" "}
                      <span class="redstyl">* </span>
                    </td>
                    <td>
                      <select
                        {...register("mediumInstTenth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        disabled={readOnly}
                        className={`${
                          errors.mediumInstTenth ? "error_msg" : ""
                        }`}
                        value={tenthMedium}
                        onChange={(e) => {
                          setTenthMedium(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("mediumInstTenth");
                        }}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.mediumOfInstruction?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {tenthMedium === "Other" && (
                        <>
                          <label>
                            {UGEducationDetails.Please_Specify}{" "}
                            <span class="redstyl">* </span>
                          </label>
                          <input
                            {...register("specifyMediumTenth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                            })}
                            autoComplete="off"
                            onChange={(e) => {
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("specifyMediumTenth");
                            }}
                            disabled={readOnly}
                            className={`${
                              errors.specifyMediumTenth ? "error_msg" : ""
                            }`}
                          />
                          {errors.specifyMediumTenth && (
                            <div className="error_shown">
                              {errors.specifyMediumTenth?.message}
                            </div>
                          )}
                        </>
                      )}
                      {errors.mediumInstTenth && (
                        <div className="error_shown">
                          {errors.mediumInstTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("mediumInstTwelveth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        disabled={readOnly}
                        value={twelvthMedium}
                        onChange={(e) => {
                          setTwelvthMedium(e.target.value);
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("mediumInstTwelveth");
                        }}
                        className={`${
                          errors.mediumInstTwelveth ? "error_msg" : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.mediumOfInstruction?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {twelvthMedium === "Other" && (
                        <>
                          <label>
                            {UGEducationDetails.Please_Specify}{" "}
                            <span class="redstyl">* </span>
                          </label>
                          <input
                            {...register("specifyMediumTwelveth", {
                              required: {
                                value: true,
                                message: "Please enter value",
                              },
                            })}
                            autoComplete="off"
                            onChange={(e) => {
                              dispatch(unsavedchanges(e.target.value));
                              clearErrors("specifyMediumTwelveth");
                            }}
                            disabled={readOnly}
                            className={`${
                              errors.specifyMediumTwelveth ? "error_msg" : ""
                            }`}
                          />
                          {errors.specifyMediumTwelveth && (
                            <div className="error_shown">
                              {errors.specifyMediumTwelveth?.message}
                            </div>
                          )}
                        </>
                      )}
                      {errors.mediumInstTwelveth && (
                        <div className="error_shown">
                          {errors.mediumInstTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.Region_Of_Residence}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="regionhelpTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="regionhelpTooltip">
                        <p>
                          {
                            UGEducationDetails.Region_Of_Residence_Tooltip_Message
                          }
                        </p>
                      </Tooltip>
                    </td>
                    <td>
                      <select
                        {...register("residenceTenth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("residenceTenth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.residenceTenth ? "error_msg" : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.regionOfResidence?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.residenceTenth && (
                        <div className="error_shown">
                          {errors.residenceTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("residenceTwelveth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("residenceTwelveth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.residenceTwelveth ? "error_msg" : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.regionOfResidence?.map((edu, ind) => (
                            <option value={edu.value}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.residenceTwelveth && (
                        <div className="error_shown">
                          {errors.residenceTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {UGEducationDetails.School_Group_12}{" "}
                      <span class="redstyl">* </span>
                      <i
                        data-tooltip-id="class12helpTooltip"
                        class="fa-solid fa-circle-info"
                      ></i>
                      <Tooltip id="class12helpTooltip">
                        <p>                         
                          {UGEducationDetails.School_Group_12_Tooltip_Message}
                        </p>
                      </Tooltip>
                    </td>
                    {/* <td></td> */}
                    <td>
                      <select
                        {...register("schoolGroupTenth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolGroupTenth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.schoolGroupTenth ? "error_msg" : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.class12SchlGroup?.map((edu, ind) => (
                            <option value={edu.Id}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.schoolGroupTenth && (
                        <div className="error_shown">
                          {errors.schoolGroupTenth?.message}
                        </div>
                      )}
                    </td>
                    <td>
                      <select
                        {...register("schoolGroupTwelveth", {
                          required: {
                            value: true,
                            message: "Please select value",
                          },
                        })}
                        onChange={(e) => {
                          dispatch(unsavedchanges(e.target.value));
                          clearErrors("schoolGroupTwelveth");
                        }}
                        disabled={readOnly}
                        className={`${
                          errors.schoolGroupTwelveth ? "error_msg" : ""
                        }`}
                      >
                        <option value="" disabled selected>
                          {AppCommon.Dropdown_Default_Option}
                        </option>
                        {pickListVal &&
                          pickListVal?.class12SchlGroup?.map((edu, ind) => (
                            <option value={edu.Id}>{edu.value}</option>
                          ))}
                      </select>
                      {errors.schoolGroupTwelveth && (
                        <div className="error_shown">
                          {errors.schoolGroupTwelveth?.message}
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3" className="bottom_clr">
                      <div class="form_styl">
                        <div class="w100 blc_txt">
                          <Notes name="Category" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="form_styl">
                <div class="w100">
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value="draft"
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {ApplicationViewPage.Draft_Button_Message}
                  </button>
                  <button
                    onClick={(e) => (DraftBtn = e.target.value)}
                    value=""
                    type="submit"
                    class={readOnly ? "submit ml_5 disabled" : "submit ml_5"}
                    disabled={readOnly}
                  >
                    {ApplicationViewPage.Save_And_Proceed_Button_Message}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Home>
  );
};

export default EducationDetailUG;
