import React, { useEffect, useState } from "react";
import * as images from "../../resources/images";
import { useForm } from "react-hook-form";
import Notification from "../footer/Notification";
import { NavLink, useNavigate } from "react-router-dom";
import { PATHS } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { userSignup } from "../../redux/actions/Api";
import { EMAIL_REGEX } from "../../utils/Regex";
import Loader from "../Loader";
import { resetLoginResponseFailure } from "../../redux/actions";
import ReactGA from 'react-ga4';

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { signupresponse, signupResponseError } = useSelector((state) => state.collections);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    eventTracker();
  }, []);

  const eventTracker = (category= 'Signup', action = "APU action", label = "APU label") => {
    ReactGA.event({category, action, label});
  }

  useEffect(() => {
    if (signupResponseError) {
      setTimeout(() => {
        setLoader(false);
      }, 200);
    }
  }, [signupResponseError]);

  const _onSubmit = (values) => {
    if (signupResponseError) {
      dispatch(resetLoginResponseFailure());
    }
   
    let formData = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.useremail,
    };
    
    if (formData) {
      setLoader(true);
      dispatch(userSignup(formData));
    }
  };

  useEffect(() => {
    if (signupresponse) {
      setTimeout(() => {
        setLoader(false);
        navigate(PATHS.RESETEMAIL);
      }, 1500);
    }
  }, [signupresponse]);


  const handleEmail = (e) => {
    let emailAddress = e.target.value;

    if (
      emailAddress.includes("@apu.edu.in") || emailAddress.includes("@azimpremjifoundation.org")
    ) {
      setError("useremail", {
        type: "custom",
        message:
          "Please note that the system does not accept Azim Premji foundation or APU email id. Kindly sign up with your personal email id. Provide your foundation email id in the alternate contact details asked for in the application form.",
      });
    } else {
      clearErrors(["useremail"])
    }
  };

  return (
    <div
      class="main_container"
      style={{
        backgroundImage: `url("https://apf-uat--uat.sandbox.my.site.com/admissions/file-asset/univ_building_8_reduced_image?v=1")`,
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div class="container_box min_hgt_100">
  {/* future inhencent  */}
         <div class="header">
           <img src={images.apuLogo1} />
         </div>
         {/* <div className="signin_head">

           <h4>APPLICANT PORTAL</h4>
           <p>This portal is for candidates who wish to apply to Azim Premji University programmes.<br />
             Signup to submit your application form.</p>

         </div> */}
        <Loader show={loader} />

        <form name="signup" onSubmit={handleSubmit(_onSubmit)}
          onFocus={(e) => e.target.setAttribute("autoComplete", "none")}>
          <div class="signup_box">
            {signupResponseError && <p className="error_shown">{signupResponseError}</p>}
            <div class="input_box">
              <label>
                First Name <span>*</span>
              </label>
              <input
                type="text"
                id="fname"
                //  className={`${errors.registerEmail ? "error_msg" : ""}`}
                {...register("firstname", {
                  required: {
                    value: true,
                    message: "First name is required",
                  },
                })}
              />
              <div className="error_shown">{errors.firstname?.message}</div>
            </div>
            <div class="input_box">
              <label>
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                id="lname"
                {...register("lastname", {
                  required: {
                    value: true,
                    message: "Last name is required",
                  },
                })}
              />
              <div className="error_shown">{errors.lastname?.message}</div>
            </div>
            <div class="input_box">
              <label>
                Email <span>*</span>
              </label>
              <input
                type="text"
                {...register("useremail", {
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Please enter a correct Email Address",
                  },
                })}
                id="email"
                onChange={handleEmail}
              />
              <div className="error_shown">{errors.useremail?.message}</div>
            </div>
            <div class="input_box">
              <button type="submit" class="button_styl">
                Sign Up
              </button>
            </div>
            <div class="input_box">
              <p>
              Already Signed Up?{" "}
                <NavLink
                  to={PATHS.USERSIGNIN}
                  style={{ textDecoration: "none" }}
                >
                  Login Here
                </NavLink>
              </p>
            </div>
          </div>
        </form>
        <Notification />
      </div>
      
    </div>
  );
};

export default Signup;
