export const CHAR_REGEX = /^([a-z]+\s)*[a-z]+$/i;
export const LNAME_CHAR_REGEX = /^([^\s][a-z])*[a-z]+$/i;
export const L_CHAR_REGEX = /^[a-zA-Z]+$/;

export const MOBILE_REGEX = /^[0-9]{10}$/i;
export const NUM_REGEX = /^\d+$/i;
export const EMAIL_REGEX_Old = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

// export const GRADE_REGEX = /^AF|WF|A|B\+|B|C\+|C|D|F$/;
export const SPECHAR_REGEX = /^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/i;
export const SPECIAL_CHAR = /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/;
export const GET_LOWER_CASE = /^(.*[a-z].*)/;
export const GET_UPPER_CASE = /^(.*[A-Z].*)/;
export const GET_NUMBER = /^(.*[0-9].*)/;
export const SPACE_REGEX = /^\s+$/;
export const E_REGEX = /^/;
export const PERCENT = /^\d*\.?\d*$/;
export const PIN_REGEX = /^[0-9]{6}$/i;
export const WHT = /^[a-zA-Z][a-zA-Z ]*$/;
// export const WHITE_SPACE = (/\s+$/, "");

export const WHITE_SPACE = /^[A-Za-z]+(?: [A-Za-z]+)*[A-Za-z]$/;
export const CGPA_REGEX = /^(\d{0,1})([.]\d{0,2})+$/i;
// export const GRADE_REGEX = /^(?:A-?|[BCD][+-]?|[AW]?F)$/;
// export const GRADE_REGEX = /^(r\d+)$/;
//  export const GRADE_REGEX = /^[ABCDEF][+-]?$/
// export const GRADE_REGEX = /^[A-Z][+-]?|\d{1,2}$/
export const GRADE_REGEX = /^[ABCDEF][+-]?$/




